/* Task.css */

.task {
  background-color: slategray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 12px;

  margin-left: 5%;
  margin-right: 5%;

  /*👉margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 12px;👈*/

  /*👉padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 12px;  👈*/

  width: 90%;
  /*👉 width: 290px;👈*/
}

.check-box-group {
  display: flex;
  align-items: center;
}

.check-box-main {
  padding-top: 3px;
}

.check-box {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  cursor: pointer;
}

.task-title {
  /*  */
  width: 230px;
  text-align: left;
  text-justify: inter-word;
  font-size: 20px;
  margin-left: 14px;
}

h3.line-through {
  text-decoration-line: line-through;
}

h3.none {
  text-decoration-line: none;
}

/*👉.deleteButton-parent {
    ➡  ⬅
    
}👈*/

.deleteButton {
  background-color: lightblue;
  font-size: 14px;
  font-weight: bold;
  margin-right: 14px;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 14px;
  cursor: pointer;
}

/* *********************************************** */
