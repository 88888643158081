/* Logout.css */

.logout-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logout-button,
.refresh-button {
  background-color: lightblue;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  width: 75px;
  height: 20px;
  padding: 5px;
  padding-bottom: 20px;
  margin-left: 24px;
}

.user-email {
  margin-left: 6px;
}

.refresh-button {
  margin-right: 24px;
}
