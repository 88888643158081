/* Login.css */

.username-field {
  /*  */
  margin: 10px;
}

.password-field {
  /*  */
  margin: 10px;
}

input {
  /*  */
  height: 48px;
  color: black;
  font-size: 24px;
  border-radius: 8px;
  overflow: hidden;
}

.login-group {
  display: flex;
  flex-direction: column;
}

.login-button {
  /*  */
  background-color: lightblue;
  margin: 10px;
}

.help-buttons {
  display: flex;
  flex-direction: row;
}

.create-button {
  background-color: lightblue;
  font-size: 14px;
  font-weight: bold;
  /*👉width: 50px;👈*/
  flex-grow: 1;
  margin: 16px;
}

.forgot-button {
  background-color: lightblue;
  font-size: 14px;
  font-weight: bold;
  /*👉width: 50px;👈*/
  flex-grow: 1;
  margin: 16px;
}

.error-container {
  border: "thick solid #0000FF";
  /*👉background-color: lightcoral;👈*/
  border-width: 33px;
  /*👉border-color: red;👈*/
}

.error-message {
  /*   */
}

.error-text {
  color: red;
}

/* *********************************************** */
